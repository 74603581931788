html{
  background-image: url("./images/khachik-simonian-nXOB-wh4Oyc-unsplash.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
  background-color: #d4d4d4;
}
body {
  background-color: rgba(201, 76, 76, 0.0);
}

.App {
  text-align: center;
  background-color: transparent;
  height: 100%;
  
}

.home {
  background-color: rgba(201, 76, 76, 0.0);
  height: 100%;
  
/*NAVBAR*/
}
a {
  font-family: 'Roboto', sans-serif;

}

a:hover{
  color: white;
    transition: all 0.5s ease-out;
}

.ATag{
  margin: 1.5% auto;
font-family: Roboto;
font-size: 1.3rem;
display: flex;
align-items: center;
color: #01D4B4;
text-decoration: none;
justify-content: center;
background-color: inherit;
}


.add-post:hover:after{
  content:' Add Post';
  font-family: "Roboto";
  font-size: 1.3rem;
  font-weight: 500;
}

.fa-sign-out-alt:hover:after{
  content:' Log Out';
  font-family: "Roboto";
  font-size: 1.3rem;
  font-weight: 500;

}

/**/

/*SIGNUP FORM CHECKBOX*/
#checkbox{
  margin:12% auto;
}
#conditions{
 align-self: center;
}



.posts-container{
  display:flex;
  flex-wrap: wrap;
  margin: 2%;
  justify-content: center;

}
.post-hero{
  margin:2%;
  padding:2%;
  background-color: white;
  border-radius: 15px;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
}
.buttons{
  margin:4% 2%;
  display:flex;
  justify-content: space-evenly;
  width:100%;
}
.imgdiv{
  width:100%;
  background-color: white;
}
h4{
  width:100%;
  background-color: white;
}
.buttons2{
  margin-top:4%;
  background-color: inherit;
  color:black;
  border-color: #FF9c00

}
.postsContainer{
  display:flex;
  flex-wrap:wrap;
  margin: 2%;
  justify-content: center;
}

img{
  border-radius: 15px;
  width:100%;
}


.large-image{
  max-width: 100vw;
  width: 100%;
  border-radius: 0px;
}


/* HOME STARTS HERE: */

.home{
  height:100vh;
  color:white;
}

.home-header{
  color:black;
  border-radius: 15px;
  width: 60%;
  margin:4% auto;
  padding: 3% 0px;
}

.home-body{
  width: 60%;
  margin: 0 auto;
  display:flex;
  justify-content: space-between;
}

.home-body-left{
  width:45%;
  background-color: rgba(0,0,0,0.6);
  border-radius: 15px;
  display:flex;
  align-items: center;
}

.home-body-right {
  width:45%;
}
.home-title{
  color: #01D4B4;
}

.home-highlight{
  color:#FF9C00
}

.home-h2 {
  font-size: 30px;
}

.home-p {
  font-size: 20px;
}

.home-large-text{
  font-size: 30px;
}

.home-button {
  background-color: transparent;
  border: 1px solid #FF9C00;
  border-radius: 5px;
  color: black;
  padding: 0 2%;
}

.home-button:hover{
  color: white;
  background-color: #4D4D4D;
  transform: scale(1.1);
}

.footerp{
color: #FF9c00;
margin:auto;

}

.h2div{
  display:flex;
  justify-content: center;

}
h3{
  margin-top: 2%;
  background: rgba(0, 0, 0, 0.4);
  max-width: 30%;
  padding: 1% 2%;
  border-radius: 10px;
  color:white;
}
.aboutcards{
  width:80%;
  margin:3% auto;
}
.indcard{
  width: 30%;
  margin: 10% 10%;
}


.errorText{
  color:red;
}


/* LOGGED OUT */

.loggedout{
  margin-top:5%;
}

.loggedout p {
margin-top: 5%;
  font-size: 1.5rem;

}
